import { CbhFeatureFlag } from "@src/appV2/FeatureFlags";
import { CbhFeatureFlags } from "@src/appV2/FeatureFlags/CbhFeatureFlags";
import { logError, logEvent } from "@src/appV2/lib/analytics";
import { USER_EVENTS } from "@src/constants";
import { Shift } from "@src/lib/interface";
import { CapacitorPedometer } from "capacitor-pedometer";
import { addMinutes, subDays } from "date-fns";

type HistogramSettings = CbhFeatureFlags[CbhFeatureFlag.PEDOMETER_HISTOGRAM_CONFIG];
interface RecordShiftPedometerDataParams {
  shift: Shift;
  histogramSettings: HistogramSettings;
}
interface HistogramData {
  steps: number;
  interval: {
    start: Date;
    end: Date;
  };
}

interface ShiftPedometerEventData {
  shiftId: string | undefined;
  numberOfStepsDuringShift: number;
  histogram?: HistogramData[];
  histogramSettings?: HistogramSettings;
}

export async function recordShiftPedometerData({
  shift,
  histogramSettings,
}: RecordShiftPedometerDataParams) {
  try {
    const isStepCountingAvailable = await CapacitorPedometer.isStepCountingAvailable();

    if (!isStepCountingAvailable) {
      logEvent(USER_EVENTS.STEP_COUNTING_API_UNAVAILABLE, {
        shiftId: shift._id,
      });

      return;
    }

    const { physicalActivity } = await CapacitorPedometer.checkPermissions();
    logEvent(USER_EVENTS.PERMISSION_CHECK, {
      permissionAlias: "physicalActivity",
      status: physicalActivity,
    });

    // If the user rejected the permission, no reason to query the data
    if (physicalActivity === "denied") {
      return;
    }

    if (!shift.clockInOut?.start) {
      logError(USER_EVENTS.SHIFT_PEDOMETER_DATA_FAILURE, {
        error: "Clock In information unavailable on the shift",
        metadata: {
          shiftId: shift._id,
        },
      });
      return;
    }

    const clockInTime = shift.clockInOut.start;

    // Consider now is the clock out time
    const clockOutTime = new Date();

    // Get total steps for the entire shift
    const { steps } = await CapacitorPedometer.readSteps({
      startAt: clockInTime,
      endAt: clockOutTime.toISOString(),
    });

    const eventData: ShiftPedometerEventData = {
      shiftId: shift._id,
      numberOfStepsDuringShift: steps,
    };

    // If histogram settings are provided, collect step data in intervals
    if (histogramSettings.enabled) {
      const { bucketSizeInMinutes } = histogramSettings;
      const histogram: HistogramData[] = [];

      let startTime = new Date(clockInTime);
      let endTime: Date;

      while (startTime < clockOutTime) {
        endTime = addMinutes(startTime, bucketSizeInMinutes);
        if (endTime > clockOutTime) {
          endTime = clockOutTime;
        }

        try {
          const { steps } = await CapacitorPedometer.readSteps({
            startAt: startTime.toISOString(),
            endAt: endTime.toISOString(),
          });

          histogram.push({
            steps,
            interval: {
              start: startTime,
              end: endTime,
            },
          });
        } catch (error) {
          logError(USER_EVENTS.SHIFT_PEDOMETER_DATA_FAILURE, {
            error,
            metadata: {
              shiftId: shift._id,
              bucket: {
                start: startTime,
                end: endTime,
              },
            },
          });
        }

        startTime = endTime;
      }

      eventData.histogram = histogram;
      eventData.histogramSettings = histogramSettings;
    }

    logEvent(USER_EVENTS.SHIFT_PEDOMETER_DATA, eventData);
  } catch (error) {
    logError(USER_EVENTS.SHIFT_PEDOMETER_DATA_FAILURE, {
      error,
      metadata: {
        shiftId: shift._id,
      },
    });
  }
}

export async function forcePedometerPermissionPrompt(shift: Shift) {
  try {
    const isStepCountingAvailable = await CapacitorPedometer.isStepCountingAvailable();

    if (!isStepCountingAvailable) {
      logEvent(USER_EVENTS.STEP_COUNTING_API_UNAVAILABLE, {
        shiftId: shift._id,
      });

      return;
    }

    const { physicalActivity } = await CapacitorPedometer.checkPermissions();
    logEvent(USER_EVENTS.PERMISSION_CHECK, {
      permissionAlias: "physicalActivity",
      status: physicalActivity,
    });

    // If the user already accepted or denied the permission, exit
    if (physicalActivity !== "prompt") {
      return;
    }

    // Use some dummy interval to read pedometer data for
    const now = new Date();
    const yesterday = subDays(now, 1);

    // Ignore the result
    await CapacitorPedometer.readSteps({
      startAt: yesterday.toISOString(),
      endAt: now.toISOString(),
    });
  } catch (error) {
    const errorCode = (error as { code?: string })?.code;
    if (errorCode && errorCode === "UNAUTHORIZED") {
      // We emit this event immediately so it's easy to check for when permissions were denied
      logEvent(USER_EVENTS.PERMISSION_CHECK, {
        permissionAlias: "physicalActivity",
        status: "denied",
      });
    } else {
      logError(USER_EVENTS.SHIFT_PEDOMETER_DATA_FAILURE, {
        error,
        metadata: {
          shiftId: shift._id,
        },
      });
    }
  }
}
